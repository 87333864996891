"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorsTable = void 0;
require("./MonitorsTable.scss");
const applications_1 = require("@ui/applications");
const notifications_1 = require("@ui/notifications");
const MonitorModal_1 = require("./MonitorModal");
const FeatureBoundary_1 = require("../../../global/ui/features/FeatureBoundary");
const MonitorsTableColumns_1 = require("./MonitorsTableColumns");
const MonitorMenu_1 = require("./monitor-form/MonitorMenu");
const text_1 = require("@ui/text");
const SortedBy_1 = require("@ui/table-modifier/SortedBy");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const MonitorTypeTag_1 = require("./MonitorTypeTag");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getRowKey = (result) => result.id;
const sortOptions = [
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'name',
        label: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'monitorTypeId',
        label: sonar_ts_constants_1.COPY_SPECIFIC.TYPE
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'applicationId',
        label: sonar_ts_constants_1.COPY_SPECIFIC.APP
    }
];
const pulseMonitorsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.monitors.get);
const postMonitorSelectors = (0, selectors_1.createSonarSelector)((state) => state.core.pulse.monitors.post);
const deleteMonitorSelectors = (0, selectors_1.createSonarSelector)((state) => state.core.pulse.monitors.byId.delete);
const monitorTypeOptions = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.PulseMonitorType)
    .filter((type) => !(MonitorTypeTag_1.monitorParentType[type] === sonar_core_1.PulseMonitorType.Personal))
    .map((value) => ({
    label: react_1.default.createElement(MonitorTypeTag_1.MonitorTypeTag, { monitorTypeId: value }),
    value
}));
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: {
        applicationId: true,
        deleted: true,
        name: { operator: sonar_ts_types_1.DynamicQueryOperator.Like },
        typeIds: {
            name: 'monitorTypeId',
            operator: sonar_ts_types_1.DynamicQueryOperator.In
        }
    }
});
const MonitorsTable = () => {
    const { id, isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const stakeholders = (0, _hooks_1.useStakeholders)();
    const muteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchMuteCorePulseMonitorsById);
    const patchMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCorePulseMonitorsById);
    const getPulseMonitors = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCorePulseMonitors);
    const deleteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCorePulseMonitorsById);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(pulseMonitorsSelectors);
    const [showModal, setShowModal] = (0, _hooks_1.useBoolean)(false);
    const [editMonitor, setEditMonitor] = (0, react_1.useState)(undefined);
    const userStakeholderId = (0, react_1.useMemo)(() => { var _a; return (_a = stakeholders.find((stakeholder) => stakeholder.userId === id)) === null || _a === void 0 ? void 0 : _a.stakeholderId; }, [id, stakeholders]);
    const columns = (0, react_1.useMemo)(() => (0, MonitorsTableColumns_1.getColumns)(isReadOnly, Number(userStakeholderId), (mute, monitor) => muteMonitor({
        id: monitor.id,
        isMuted: mute
    }), (subscribe, monitor) => {
        var _a, _b;
        return patchMonitor({
            id: monitor.id,
            stakeholderIds: subscribe ?
                [...(_a = monitor === null || monitor === void 0 ? void 0 : monitor.stakeholderIds) !== null && _a !== void 0 ? _a : [], Number(userStakeholderId)] :
                ((_b = monitor === null || monitor === void 0 ? void 0 : monitor.stakeholderIds) !== null && _b !== void 0 ? _b : []).filter((stakeholderId) => stakeholderId !== userStakeholderId)
        });
    }), [isReadOnly, muteMonitor, patchMonitor, userStakeholderId]);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            deleted: false,
            sortBy: sortOptions[0].key,
            sortDirection: sortOptions[0].direction
        },
        onUpdate: (newParams, prev, combined) => {
            const { key, paginationDirection } = newParams;
            const params = convertToDql(Object.assign(Object.assign({}, combined), { key,
                paginationDirection }));
            getPulseMonitors(params);
        }
    });
    (0, _hooks_1.useOnMount)(() => {
        updateParams({});
    });
    const handleSortUpdate = (0, react_1.useCallback)((update) => {
        updateParams(update);
    }, [updateParams]);
    const handleNameChange = (0, react_1.useCallback)((name) => {
        updateParams({ name });
    }, [updateParams]);
    const handleTypeSelect = (0, react_1.useCallback)((typeId) => {
        const typeIds = typeId === sonar_core_1.PulseMonitorType.Personal ? Object.keys(MonitorTypeTag_1.monitorParentType).map(Number) : [typeId];
        updateParams({ typeIds });
    }, [updateParams]);
    const handleAppSelect = (0, react_1.useCallback)((applicationId) => {
        updateParams({ applicationId });
    }, [updateParams]);
    const handleDeleteMonitor = (0, react_1.useCallback)((monitor) => {
        deleteMonitor({ monitorId: monitor.id });
    }, [deleteMonitor]);
    const handleEditMonitor = (0, react_1.useCallback)((monitor) => {
        setEditMonitor(monitor);
        setShowModal.on();
    }, [setShowModal]);
    const handleOpenModal = (0, react_1.useCallback)(() => {
        setEditMonitor(undefined);
        setShowModal.on();
    }, [setShowModal]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(general_1.SonarCard, { className: 'monitors-table-card', extra: react_1.default.createElement(general_1.FullUserBoundary, null,
                react_1.default.createElement(FeatureBoundary_1.FeatureBoundary, { feature: sonar_core_1.FeatureFlag.PulseDashboard },
                    react_1.default.createElement(antd_1.Button, { onClick: handleOpenModal }, sonar_ts_constants_1.COPY_SPECIFIC.CREATE_NEW_MONITOR))), noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.MONITORS },
            react_1.default.createElement(antd_1.Flex, { align: 'end', className: 'filter-section', justify: 'space-between' },
                react_1.default.createElement("div", { className: 'table-filters' },
                    react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                        react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.NAME),
                        react_1.default.createElement(general_1.SearchInput, { className: 'monitor-name-input', onChange: handleNameChange, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_MONITORS })),
                    react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                        react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.TYPE),
                        react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'type-select', onChange: handleTypeSelect, options: monitorTypeOptions, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(sonar_ts_constants_1.COPY_SPECIFIC.TYPE), showSearch: true })),
                    react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                        react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.APP),
                        react_1.default.createElement(applications_1.AppSelect, { nameAndLabel: true, onChange: handleAppSelect, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(sonar_ts_constants_1.COPY_SPECIFIC.APP) }))),
                react_1.default.createElement(SortedBy_1.SortedBySelect, { defaultValue: sortOptions[0], onChange: handleSortUpdate, queryParams: queryParams, sortOptions: sortOptions })),
            react_1.default.createElement(PaginatedTable_1.PaginatedTable, { canDeleteRow: (record) => {
                    var _a;
                    return Boolean(MonitorMenu_1.monitorConfigs.find((config) => config.type === record.monitorTypeId)) &&
                        !((_a = MonitorMenu_1.monitorConfigs.find((config) => config.type === record.monitorTypeId)) === null || _a === void 0 ? void 0 : _a.intrinsic);
                }, canEditRow: (record) => Boolean(MonitorMenu_1.monitorConfigs.find((config) => config.type === record.monitorTypeId)), columns: columns, id: 'monitors-table', loading: isRequested, onChange: updateParams, onDelete: handleDeleteMonitor, onEdit: handleEditMonitor, resource: data, rowKey: getRowKey, scroll: {
                    scrollToFirstRowOnChange: true,
                    x: 'max-content'
                }, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection, sticky: true }),
            react_1.default.createElement(MonitorModal_1.CreateMonitorModal, { monitor: editMonitor, onCancel: setShowModal.off, onSave: setShowModal.off, open: showModal })),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.SETTINGS_PULSE_COPY.MONITOR_SAVED, resetRawAction: sonar_core_1.coreActions.resetPostCorePulseMonitors, stateSelector: postMonitorSelectors }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.SETTINGS_PULSE_COPY.MONITOR_DELETED, resetRawAction: sonar_core_1.coreActions.resetDeleteCorePulseMonitorsById, stateSelector: deleteMonitorSelectors })));
};
exports.MonitorsTable = MonitorsTable;
