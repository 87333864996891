"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.darkTheme = exports.defaultTheme = void 0;
const antd_1 = require("antd");
exports.defaultTheme = {
    components: {
        Breadcrumb: {
            colorBgTextHover: 'transparent'
        },
        Card: {
            borderRadius: 4
        },
        Tag: {
            defaultBg: '#fafafa'
        }
    },
    token: {
        borderRadius: 4,
        // eslint-disable-next-line max-len
        fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Arial, \'Noto Sans\', sans-serif, \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\', \'Noto Color Emoji\''
    }
};
exports.darkTheme = Object.assign(Object.assign({ algorithm: antd_1.theme.darkAlgorithm }, exports.defaultTheme), { components: Object.assign({}, exports.defaultTheme.components), token: {
        colorBorder: 'rgba(250, 250, 250, 0.45)',
        colorTextPlaceholder: 'rgba(255, 255, 255, 0.45)'
    } });
