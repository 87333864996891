"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFilterComponent = void 0;
const Active_1 = require("@ui/table-modifier/filters/Active");
const Application_1 = require("@ui/table-modifier/filters/Application");
const ComplianceCategory_1 = require("@ui/table-modifier/filters/ComplianceCategory");
const Date_1 = require("@ui/table-modifier/filters/Date");
const FieldType_1 = require("@ui/table-modifier/filters/FieldType");
const Object_1 = require("@ui/table-modifier/filters/Object");
const Owner_1 = require("@ui/table-modifier/filters/Owner");
const PageLayout_1 = require("@ui/table-modifier/filters/PageLayout");
const Populated_1 = require("../filters/Populated");
const Profile_1 = require("@ui/table-modifier/filters/Profile");
const SalesforceUser_1 = require("@ui/table-modifier/filters/SalesforceUser");
const sonar_core_1 = require("@sonar-software/sonar-core");
const Stakeholder_1 = require("@ui/table-modifier/filters/Stakeholder");
const Status_1 = require("@ui/table-modifier/filters/Status");
const Tags_1 = require("@ui/table-modifier/filters/Tags");
const Text_1 = require("@ui/table-modifier/filters/Text");
const formatFilterMap = {
    [sonar_core_1.SchemaFormat.Checkmark]: Active_1.ActiveFilter,
    [sonar_core_1.SchemaFormat.DateTime]: Date_1.DateFilter,
    [sonar_core_1.SchemaFormat.Object]: Object_1.ObjectFilter,
    [sonar_core_1.SchemaFormat.Owner]: Owner_1.OwnerFilter,
    [sonar_core_1.SchemaFormat.PageLayouts]: PageLayout_1.PageLayoutFilter,
    [sonar_core_1.SchemaFormat.Profiles]: Profile_1.ProfileFilter,
    [sonar_core_1.SchemaFormat.Primary]: Text_1.TextFilter,
    [sonar_core_1.SchemaFormat.Text]: Text_1.TextFilter,
    [sonar_core_1.SchemaFormat.SalesforceUser]: SalesforceUser_1.SalesforceUserFilter,
    [sonar_core_1.SchemaFormat.Stakeholder]: Stakeholder_1.StakeholderFilter,
    [sonar_core_1.SchemaFormat.Tags]: Tags_1.TagsFilter,
    [sonar_core_1.SchemaFormat.Apps]: Application_1.ApplicationFilter,
    [sonar_core_1.SchemaFormat.Description]: Populated_1.Populated,
    [sonar_core_1.SchemaFormat.HelpText]: Populated_1.Populated
};
const enumFilterMap = {
    ComplianceCategory: ComplianceCategory_1.ComplianceCategoryFilter,
    Status: Status_1.StatusFilter,
    Type: FieldType_1.FieldTypeFilter
};
const getFilterComponent = (filter) => {
    var _a, _b;
    if (filter.filterFormat === sonar_core_1.SchemaFormat.Enum) {
        return (_a = enumFilterMap[filter.filterId]) !== null && _a !== void 0 ? _a : null;
    }
    return (_b = formatFilterMap[filter.filterFormat]) !== null && _b !== void 0 ? _b : null;
};
exports.getFilterComponent = getFilterComponent;
