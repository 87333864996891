"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchInput = void 0;
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
exports.SearchInput = (0, react_1.forwardRef)(({ autoFocus, className, debounceDelay = 300, defaultValue, id, onChange, placeholder = 'Search', size }, ref) => {
    const { token } = antd_1.theme.useToken();
    const [text, setText] = (0, react_1.useState)(defaultValue);
    const inputRef = (0, react_1.useRef)(null);
    const onChangeRef = (0, react_1.useRef)(onChange);
    const timeoutRef = (0, react_1.useRef)(0);
    (0, react_1.useImperativeHandle)(ref, () => ({
        inputRef: inputRef.current,
        setText: (value) => setText(value)
    }), []);
    onChangeRef.current = onChange;
    const handleChange = (0, react_1.useCallback)((e) => {
        var _a, _b;
        const value = (_a = e.target) === null || _a === void 0 ? void 0 : _a.value;
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(() => {
            var _a;
            onChangeRef.current((_a = value === null || value === void 0 ? void 0 : value.trim()) !== null && _a !== void 0 ? _a : '');
        }, debounceDelay);
        setText((_b = e.target) === null || _b === void 0 ? void 0 : _b.value);
    }, [debounceDelay]);
    return (react_1.default.createElement(antd_1.Input, { allowClear: true, autoFocus: autoFocus, className: className, defaultValue: defaultValue, id: id, onChange: handleChange, placeholder: placeholder, ref: inputRef, size: size, suffix: !text && react_1.default.createElement(icons_1.SearchOutlined, { style: { color: token.colorTextPlaceholder } }), title: 'Search', value: text }));
});
