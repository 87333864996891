"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplianceCategoryFilter = void 0;
require("./ComplianceCategory.scss");
const icons_1 = require("@ui/icons");
const sonar_core_1 = require("@sonar-software/sonar-core");
const FilterTag_1 = require("./FilterTag");
const popups_1 = require("@lib/popups");
const text_1 = require("@ui/text");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const filters_1 = require("@periscopes/general/filters");
const react_1 = __importStar(require("react"));
const complianceCategories = Object.entries(sonar_core_1.ComplianceCategory).filter(([, id]) => typeof id === 'number');
const options = complianceCategories.map(([label, value]) => ({
    label,
    value
}));
const transformSelection = (selected, previousSelection) => {
    if (!selected.length) {
        return undefined;
    }
    if (selected.includes(sonar_core_1.ComplianceCategory.None) && !previousSelection.includes(sonar_core_1.ComplianceCategory.None)) {
        return [sonar_core_1.ComplianceCategory.None];
    }
    else if (selected.includes(sonar_core_1.ComplianceCategory.Any) && !previousSelection.includes(sonar_core_1.ComplianceCategory.Any)) {
        return [sonar_core_1.ComplianceCategory.Any];
    }
    return selected.filter((categoryId) => categoryId !== sonar_core_1.ComplianceCategory.None && categoryId !== sonar_core_1.ComplianceCategory.Any);
};
const ComplianceCategoryFilter = ({ displayAsTag, id, format, label, onChange, value }) => {
    const selectedCategories = (0, react_1.useMemo)(() => (0, lib_1.splitComplianceCategories)(value), [value]);
    if (displayAsTag) {
        return (react_1.default.createElement(FilterTag_1.FilterTag, { clearValue: [], format: format, id: id, label: sonar_ts_constants_1.COPY_SPECIFIC.COMPLIANCE_CATEGORIES, update: onChange, value: selectedCategories
                .map((categoryId) => sonar_core_1.ComplianceCategory[categoryId])
                .join(', ') }));
    }
    return (react_1.default.createElement("div", { className: 'filter-container' },
        react_1.default.createElement(text_1.SonarText, null, label),
        react_1.default.createElement(filters_1.OptionFilter, { className: 'filter', getPopupContainer: popups_1.getDirectParent, id: (0, lib_1.getFilterId)(String(id)), maxTagCount: 'responsive', mode: 'multiple', onChange: (newSelection) => {
                const selection = transformSelection(newSelection, selectedCategories);
                onChange({
                    [id]: {
                        format,
                        value: selection ? (0, lib_1.aggregateComplianceCategories)(selection) : undefined
                    }
                });
            }, options: options, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(label), showArrow: true, tagRender: (option) => (Number(option.value) === 255 || Number(option.value) === 0 ?
                react_1.default.createElement("div", null, option.label) :
                react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: Number(option.value), direction: 'horizontal' })), value: selectedCategories })));
};
exports.ComplianceCategoryFilter = ComplianceCategoryFilter;
