"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorApplication = void 0;
exports.getApplicationList = getApplicationList;
require("./MonitorApplication.scss");
const applications_1 = require("@ui/applications");
const MonitorMenu_1 = require("./MonitorMenu");
const text_1 = require("@ui/text");
const usePulseMonitors_1 = require("@hooks/pulse/usePulseMonitors");
const react_if_1 = require("react-if");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function getApplicationList(monitors, applications, type) {
    var _a, _b;
    if (type === undefined) {
        return undefined;
    }
    const monitorsByType = monitors.filter((monitor) => monitor.monitorTypeId === type);
    const maxCount = (_b = (_a = MonitorMenu_1.monitorConfigs.find((config) => config.type === type)) === null || _a === void 0 ? void 0 : _a.max) !== null && _b !== void 0 ? _b : 1;
    return applications.reduce((result, application) => (monitorsByType
        .filter((monitor) => monitor.applicationId === application.applicationId)
        .length >= maxCount ?
        [...result] :
        [...result, {
                label: application.name,
                value: application.applicationId
            }]), []);
}
const MonitorApplication = ({ editing, fieldId, hidden = false, monitor, onChange }) => {
    const monitors = (0, usePulseMonitors_1.usePulseMonitors)();
    const applications = (0, _hooks_1.usePulseApps)();
    const previousMonitor = (0, _hooks_1.usePrevious)(monitor);
    const applicationOptions = (0, react_1.useMemo)(() => getApplicationList(monitors, applications, monitor.monitorTypeId), [monitors, applications, monitor]);
    const defaultApp = (0, react_1.useMemo)(() => {
        if (monitor.applicationId) {
            return monitor.applicationId;
        }
        const appOptions = getApplicationList(monitors, applications, monitor.monitorTypeId);
        if ((previousMonitor === null || previousMonitor === void 0 ? void 0 : previousMonitor.applicationId) && (appOptions === null || appOptions === void 0 ? void 0 : appOptions.find((x) => x.value === previousMonitor.applicationId))) {
            return previousMonitor.applicationId;
        }
        else if ((appOptions === null || appOptions === void 0 ? void 0 : appOptions.length) === 1) {
            return appOptions[0].value;
        }
        return undefined;
    }, [monitor, applications, monitors, previousMonitor]);
    const handleOnChange = (0, react_1.useCallback)((newApp) => {
        if ((previousMonitor === null || previousMonitor === void 0 ? void 0 : previousMonitor.applicationId) !== newApp && onChange) {
            onChange(newApp);
        }
    }, [previousMonitor, onChange]);
    (0, react_1.useEffect)(() => {
        if (monitor.monitorTypeId !== (previousMonitor === null || previousMonitor === void 0 ? void 0 : previousMonitor.monitorTypeId) || monitor.applicationId !== defaultApp) {
            handleOnChange(defaultApp);
        }
    }, [defaultApp, previousMonitor, handleOnChange, monitor]);
    if (!monitor.monitorTypeId) {
        return;
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_if_1.When, { condition: !hidden },
            react_1.default.createElement(text_1.SonarText, { className: 'h3' }, sonar_ts_constants_1.COPY_SPECIFIC.SELECT_AN_APP)),
        react_1.default.createElement(antd_1.Form.Item, { className: 'monitor-application', hidden: hidden, initialValue: defaultApp, name: fieldId === undefined ? 'applicationId' : [fieldId, 'applicationId'] },
            react_1.default.createElement(antd_1.Select, { disabled: !editing, onChange: handleOnChange, optionLabelProp: 'label', optionRender: (app) => react_1.default.createElement(applications_1.AppDisplay, { appId: Number(app.value) }), options: applicationOptions, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SELECT_AN_APPLICATION, showSearch: true }))));
};
exports.MonitorApplication = MonitorApplication;
