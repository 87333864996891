"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormList = useFormList;
const lodash_1 = require("lodash");
const antd_1 = require("antd");
const react_1 = require("react");
function useFormList(options) {
    const { required, formListName, keyName } = options;
    const [state, setState] = (0, react_1.useState)([]);
    const [form] = antd_1.Form.useForm();
    const requiredFields = (0, react_1.useRef)(required);
    const isValid = state.every((x) => x.isValid);
    const validateItem = (0, react_1.useCallback)((element, fieldName) => {
        var _a, _b, _c;
        const hasAllValues = (requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.current) ?
            Boolean((_a = requiredFields.current) === null || _a === void 0 ? void 0 : _a.every((name) => {
                const data = element[name];
                return (0, lodash_1.isArray)(data) ?
                    data.length > 0 :
                    Boolean(data);
            })) :
            true;
        const hasErrors = ((_c = (_b = form.getFieldsError()) === null || _b === void 0 ? void 0 : _b.filter((entry) => entry.errors.length &&
            entry.name[1] === fieldName)) === null || _c === void 0 ? void 0 : _c.length) > 0;
        return hasAllValues && !hasErrors;
    }, [form]);
    const validate = (0, react_1.useCallback)(() => {
        setTimeout(() => {
            var _a;
            const values = (_a = form.getFieldValue(formListName)) === null || _a === void 0 ? void 0 : _a.filter((x) => x !== undefined);
            const record = [];
            if ((values === null || values === void 0 ? void 0 : values.length) > 0) {
                values.forEach((element, index) => {
                    var _a, _b;
                    const key = form.getFieldValue([formListName, index, keyName]);
                    const isValidItem = validateItem(element, index);
                    record.push({
                        isInEdit: (_b = (_a = state.find((x) => x.key === key)) === null || _a === void 0 ? void 0 : _a.isInEdit) !== null && _b !== void 0 ? _b : !isValidItem,
                        isValid: isValidItem,
                        key: key
                    });
                });
            }
            setState(record);
        });
    }, [form, formListName, keyName, state, validateItem]);
    const reset = (0, react_1.useCallback)(() => {
        form.resetFields();
    }, [form]);
    const setEdit = (0, react_1.useCallback)((fieldName, edit, useUpdater = false) => {
        var _a;
        const key = (_a = form.getFieldValue([formListName, fieldName, keyName])) !== null && _a !== void 0 ? _a : fieldName;
        if (useUpdater) {
            setState((prevRecords) => {
                let records = prevRecords;
                const index = records.findIndex((x) => x.key === key);
                if (index === -1) {
                    const item = form.getFieldValue([formListName, fieldName]);
                    records = [...records, {
                            isInEdit: edit,
                            isValid: validateItem(item, fieldName),
                            key: key
                        }];
                }
                else {
                    const record = Object.assign(Object.assign({}, records[index]), { isInEdit: edit });
                    records[index] = record;
                }
                return records;
            });
        }
        else {
            let records = [...state];
            const index = records.findIndex((x) => x.key === key);
            if (index === -1) {
                const item = form.getFieldValue([formListName, fieldName]);
                records = [...records, {
                        isInEdit: edit,
                        isValid: validateItem(item, fieldName),
                        key: key
                    }];
            }
            else {
                const record = Object.assign(Object.assign({}, records[index]), { isInEdit: edit });
                records[index] = record;
            }
            setState(records);
        }
    }, [form, formListName, keyName, state, validateItem]);
    const getItemState = (0, react_1.useCallback)((fieldName, defaultEditState) => {
        const key = form.getFieldValue([formListName, fieldName, keyName]);
        let records = [...state];
        const itemState = records.find((x) => x.key === key);
        if (itemState === undefined) {
            const item = form.getFieldValue([formListName, fieldName]);
            const valid = validateItem(item, fieldName);
            const newRecord = {
                isInEdit: defaultEditState !== null && defaultEditState !== void 0 ? defaultEditState : !valid,
                isValid: valid,
                key: key
            };
            records = [...records, newRecord];
            setState(records);
            return newRecord;
        }
        return itemState;
    }, [form, formListName, keyName, state, validateItem]);
    const getItem = (0, react_1.useCallback)((fieldName) => form.getFieldValue([formListName, fieldName]), [form, formListName]);
    const formResult = (0, react_1.useMemo)(() => (Object.assign(Object.assign({}, form), { getItem,
        getItemState,
        isValid,
        reset,
        setEdit,
        state,
        validate })), [form, getItem, getItemState, isValid, reset, setEdit, state, validate]);
    return {
        Form: antd_1.Form,
        form: formResult
    };
}
