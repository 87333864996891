"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTagSubMenu = useTagSubMenu;
require("./TagsMainMenu.scss");
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const themes_1 = require("@ui/themes");
const events_1 = require("@lib/events");
const react_router_dom_1 = require("react-router-dom");
const antd_1 = require("antd");
const general_1 = require("@ui/general");
const icons_1 = require("@ant-design/icons");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const tags_1 = require("@ui/tags");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function buildSubMenuItem(tags, isReadOnly, isCollapsed, handleTagClick, handleSearchChange, filterValue) {
    var _a, _b;
    const topLabel = isReadOnly ?
        sonar_ts_constants_1.COPY_SONAR_OBJECT_BASE_PLURAL.TAG :
        react_1.default.createElement(antd_1.Row, { justify: 'space-between' },
            sonar_ts_constants_1.COPY_SONAR_OBJECT_BASE_PLURAL.TAG,
            isCollapsed ?
                react_1.default.createElement(icons_1.SettingOutlined, null) :
                react_1.default.createElement(react_router_dom_1.Link, { className: 'manager-link', onClick: events_1.haltClickEventPropagation, to: sonar_ts_constants_1.FULL_ROUTES.TAGS_MANAGE },
                    react_1.default.createElement(icons_1.SettingOutlined, null)));
    const subMenuItem = {
        children: isCollapsed ? undefined : [],
        className: 'tag-submenu',
        key: sonar_ts_constants_1.FULL_ROUTES.TAGS_MANAGE,
        label: topLabel,
        route: isCollapsed ? sonar_ts_constants_1.FULL_ROUTES.TAGS_MANAGE : undefined
    };
    if (!isCollapsed && (tags === null || tags === void 0 ? void 0 : tags.length) > 10) {
        (_a = subMenuItem.children) === null || _a === void 0 ? void 0 : _a.push({
            className: 'tag-search-entry',
            key: 'tag-search',
            label: react_1.default.createElement(themes_1.DarkTheme, null,
                react_1.default.createElement("div", { id: 'tag-search' },
                    react_1.default.createElement(general_1.SearchInput, { className: 'tag-search-input', onChange: handleSearchChange, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_TAGS })))
        });
    }
    if (!isCollapsed) {
        const matchesFilter = (tag) => {
            if (!filterValue) {
                return true;
            }
            return tag.name.toLowerCase().includes(filterValue.toLowerCase());
        };
        for (const tag of tags.filter(matchesFilter)) {
            (_b = subMenuItem.children) === null || _b === void 0 ? void 0 : _b.push({
                className: 'tag-submenu-item',
                id: `tag-${tag.id}`,
                key: `tag-${tag.id}`,
                label: react_1.default.createElement(tags_1.SonarTagDisplay, { noBorder: true, tagId: tag.id }),
                onClick: () => handleTagClick(tag)
            });
        }
    }
    return subMenuItem;
}
function useTagSubMenu() {
    const { isClosed } = (0, _hooks_1.useApplicationSidebar)();
    const [filterValue, setFilterValue] = (0, react_1.useState)('');
    const tags = (0, _hooks_1.useTags)();
    const { startPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const handleTagClick = (0, react_1.useCallback)((tag) => {
        startPeriscope({
            from: sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.MAIN_MENU,
            id: tag.id,
            name: tag.name,
            type: sonar_ts_types_1.SonarObjectType.SonarTag
        });
    }, [startPeriscope]);
    const subMenu = (0, react_1.useMemo)(() => buildSubMenuItem(tags, isReadOnly, isClosed, handleTagClick, setFilterValue, filterValue), [filterValue, handleTagClick, isClosed, isReadOnly, tags]);
    return subMenu;
}
