"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisualPicker = void 0;
require("./VisualPicker.scss");
const text_1 = require("@ui/text");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const icons_1 = __importStar(require("@ant-design/icons"));
const react_1 = __importStar(require("react"));
const VisualPicker = ({ defaultValue, message, name, options, onChange }) => {
    const handleOnChange = (0, react_1.useCallback)((e) => {
        if (onChange) {
            onChange(e.target.value);
        }
    }, [onChange]);
    const handleOnReset = (0, react_1.useCallback)(() => {
        if (onChange) {
            onChange(undefined);
        }
    }, [onChange]);
    return (react_1.default.createElement(antd_1.Form.Item, { className: 'visual-picker', initialValue: defaultValue, name: name, onReset: handleOnReset, valuePropName: 'checked' },
        react_1.default.createElement(antd_1.Radio.Group, { className: 'visual-picker-group', defaultValue: defaultValue, onChange: handleOnChange, value: defaultValue }, options.map((option) => react_1.default.createElement(antd_1.Tooltip, { key: option.value, open: option.disabled ? undefined : false, placement: 'bottom', title: message },
            react_1.default.createElement(antd_1.Radio.Button, { className: 'visual-picker-group-item', disabled: option.disabled, value: option.value },
                react_1.default.createElement(text_1.SonarText, { className: 'visual-picker-group-item-title', weight: 'semibold' }, option.label),
                react_1.default.createElement(react_if_1.When, { condition: !option.disabled },
                    react_1.default.createElement(icons_1.default, { className: 'visual-picker-checkmark-area', component: () => react_1.default.createElement(icons_1.CheckOutlined, { className: 'visual-picker-checkmark-icon' }) }))))))));
};
exports.VisualPicker = VisualPicker;
