"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupAuditTrailTable = void 0;
require("./SetupAuditTrailTable.scss");
const Actions_1 = require("./filters/Actions");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const SetupAuditTrailTableColumns_1 = require("./SetupAuditTrailTableColumns");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const Section_1 = require("./filters/Section");
const SourceNamePrefix_1 = require("./filters/SourceNamePrefix");
const lodash_1 = require("lodash");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const table_modifier_1 = require("@ui/table-modifier");
const _hooks_1 = require("@hooks");
const getRowKey = (result) => `${result.auditDate}-${(0, lodash_1.uniqueId)()}`;
const columns = (0, SetupAuditTrailTableColumns_1.getColumns)();
const filters = [{
        filterComponent: Actions_1.ActionFilter,
        filterFormat: sonar_core_1.SchemaFormat.Text,
        filterId: 'Action',
        filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.ACTION
    }, {
        filterFormat: sonar_core_1.SchemaFormat.SalesforceUser,
        filterId: 'User',
        filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.USER
    }, {
        filterComponent: SourceNamePrefix_1.SourceNamePrefixFilter,
        filterFormat: sonar_core_1.SchemaFormat.Object,
        filterId: 'SourceNamePrefix',
        filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.SOURCE_NAMESPACE_PREFIX
    }, {
        filterComponent: Section_1.SectionFilter,
        filterFormat: sonar_core_1.SchemaFormat.Object,
        filterId: 'Section',
        filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.SECTION
    }, {
        filterFormat: sonar_core_1.SchemaFormat.SalesforceUser,
        filterId: 'DelegateUser',
        filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.DELEGATE_USER
    }];
const sortOptions = [
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Descending,
        key: 'AuditDate',
        label: sonar_ts_constants_1.COPY_SPECIFIC.DATE
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'Action',
        label: sonar_ts_constants_1.COPY_SPECIFIC.ACTION
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'SourceNamePrefix',
        label: sonar_ts_constants_1.COPY_SPECIFIC.SOURCE_NAMESPACE_PREFIX
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'Section',
        label: sonar_ts_constants_1.COPY_SPECIFIC.SECTION
    }
];
const setupAuditTrailSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.setupAuditTrail.get);
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: {
        Action: {
            operator: sonar_ts_types_1.DynamicQueryOperator.Like,
            value: (filter) => filter === null || filter === void 0 ? void 0 : filter.value
        },
        DelegateUser: {
            operator: sonar_ts_types_1.DynamicQueryOperator.In,
            value: (filter) => filter === null || filter === void 0 ? void 0 : filter.value
        },
        Section: {
            operator: sonar_ts_types_1.DynamicQueryOperator.In,
            value: (filter) => filter === null || filter === void 0 ? void 0 : filter.value
        },
        SourceNamePrefix: {
            operator: sonar_ts_types_1.DynamicQueryOperator.In,
            value: (filter) => filter === null || filter === void 0 ? void 0 : filter.value
        },
        User: {
            operator: sonar_ts_types_1.DynamicQueryOperator.In,
            value: (filter) => filter === null || filter === void 0 ? void 0 : filter.value
        },
        start: {
            name: 'AuditDate',
            rangeEnd: 'end'
        }
    }
});
const SetupAuditTrailTable = ({ dateRange }) => {
    const getSetupAuditTrailEvents = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSetupAuditTrail);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(setupAuditTrailSelectors);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            sortBy: sortOptions[0].key,
            sortDirection: sortOptions[0].direction
        },
        onUpdate: (newParams, prev, combined) => {
            const { key, paginationDirection } = newParams;
            const dql = convertToDql(Object.assign(Object.assign({}, combined), { key,
                paginationDirection }));
            getSetupAuditTrailEvents(Object.assign(Object.assign({}, dql), { filters: newParams.filters === '' ? newParams.filters : dql.filters }));
        }
    });
    (0, react_1.useEffect)(() => {
        if (dateRange.end !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.end) || dateRange.start !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.start)) {
            const { start, end } = dateRange;
            updateParams({
                end,
                start
            });
        }
    }, [dateRange, queryParams, queryParams === null || queryParams === void 0 ? void 0 : queryParams.end, queryParams === null || queryParams === void 0 ? void 0 : queryParams.start, updateParams]);
    return (react_1.default.createElement("div", { id: 'setup-audit-trail-table' },
        react_1.default.createElement(table_modifier_1.TableModifier, { convertToDynamicQueryLanguage: (params) => {
                updateParams(params);
                return convertToDql(params);
            }, filterDisplayType: 'menu', filters: filters, filtersString: queryParams.filters, onChange: updateParams, orderString: queryParams.ordering, sortOptions: sortOptions }),
        react_1.default.createElement(PaginatedTable_1.PaginatedTable, { columns: columns, defaultPageSize: queryParams.count, loading: isRequested, onChange: updateParams, resource: data, rowKey: getRowKey, scroll: {
                scrollToFirstRowOnChange: true,
                x: 'max-content'
            }, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection, sticky: true })));
};
exports.SetupAuditTrailTable = SetupAuditTrailTable;
