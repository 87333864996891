"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorMenu = exports.monitorConfigs = exports.defaultConfig = void 0;
exports.getMenuItems = getMenuItems;
require("./MonitorMenu.scss");
const lodash_1 = require("lodash");
const MonitorApplication_1 = require("./MonitorApplication");
const MonitorDetails_1 = require("./MonitorDetails");
const MonitorNotifications_1 = require("./MonitorNotifications");
const text_1 = require("@ui/text");
const antd_1 = require("antd");
const usePulseMonitors_1 = require("@hooks/pulse/usePulseMonitors");
const form_items_1 = require("@ui/form-items");
const react_if_1 = require("react-if");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
exports.defaultConfig = {
    applicationId: undefined,
    enableOnWeekends: undefined,
    monitorTypeId: undefined,
    monitoringWindow: undefined,
    name: '',
    stakeholderIds: []
};
exports.monitorConfigs = [
    {
        active: true,
        default: Object.assign(Object.assign({}, exports.defaultConfig), { monitorTypeId: sonar_core_1.PulseMonitorType.NewObjectAndFieldAccess, name: sonar_ts_constants_1.SETTINGS_PULSE_COPY.OBJECT_AND_FIELD_ACCESS }),
        intrinsic: true,
        max: 1,
        title: sonar_ts_constants_1.SETTINGS_PULSE_COPY.OBJECT_AND_FIELD_ACCESS,
        type: sonar_core_1.PulseMonitorType.NewObjectAndFieldAccess
    },
    {
        active: true,
        default: Object.assign(Object.assign({}, exports.defaultConfig), { enableOnWeekends: false, monitorTypeId: sonar_core_1.PulseMonitorType.Uptime, monitoringWindow: sonar_core_1.MonitoringWindowType.Daily }),
        intrinsic: false,
        max: 1,
        title: sonar_ts_constants_1.SETTINGS_PULSE_COPY.UPTIME,
        type: sonar_core_1.PulseMonitorType.Uptime
    },
    {
        active: true,
        default: Object.assign(Object.assign({}, exports.defaultConfig), { monitorTypeId: sonar_core_1.PulseMonitorType.NewUser }),
        intrinsic: false,
        max: 1,
        title: sonar_ts_constants_1.SETTINGS_PULSE_COPY.NEW_USER,
        type: sonar_core_1.PulseMonitorType.NewUser
    }
];
function getMenuItems(monitors, applicationIds) {
    const menuItems = exports.monitorConfigs.filter((x) => !x.intrinsic && x.active).reduce((result, config) => {
        config.disabled = applicationIds.every((appId) => monitors.filter((monitor) => monitor.monitorTypeId === config.type &&
            monitor.applicationId === appId).length >= config.max);
        return [...result, config];
    }, []);
    return menuItems;
}
const MonitorMenu = ({ editing = true, fieldId, monitor, onChange, overrideMonitors }) => {
    var _a;
    const monitors = (0, usePulseMonitors_1.usePulseMonitors)();
    const applications = (0, _hooks_1.usePulseApps)();
    const isExistingMonitor = (monitor === null || monitor === void 0 ? void 0 : monitor.id) || !editing || (fieldId &&
        (((overrideMonitors === null || overrideMonitors === void 0 ? void 0 : overrideMonitors.length) === 1 && overrideMonitors[0].monitorTypeId === (monitor === null || monitor === void 0 ? void 0 : monitor.monitorTypeId)) ||
            ((monitor === null || monitor === void 0 ? void 0 : monitor.monitorTypeId) && ((_a = exports.monitorConfigs.find((x) => x.type === monitor.monitorTypeId)) === null || _a === void 0 ? void 0 : _a.intrinsic))));
    const monitorTypeOptions = (0, react_1.useMemo)(() => {
        var _a, _b, _c;
        if (isExistingMonitor) {
            return [{
                    disabled: false,
                    label: (_b = (_a = exports.monitorConfigs.find((x) => x.type === (monitor === null || monitor === void 0 ? void 0 : monitor.monitorTypeId))) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '',
                    value: (_c = monitor === null || monitor === void 0 ? void 0 : monitor.monitorTypeId) !== null && _c !== void 0 ? _c : 1
                }];
        }
        const applicationIds = applications.map((app) => app.applicationId);
        const menuItems = getMenuItems(overrideMonitors !== null && overrideMonitors !== void 0 ? overrideMonitors : monitors, (monitor === null || monitor === void 0 ? void 0 : monitor.applicationId) ? [monitor === null || monitor === void 0 ? void 0 : monitor.applicationId] : applicationIds);
        return menuItems.map((config) => ({
            disabled: config.disabled,
            label: config.title,
            value: config.type
        }));
    }, [applications, isExistingMonitor, monitor, monitors, overrideMonitors]);
    const defaultMonitor = (0, react_1.useMemo)(() => {
        var _a, _b;
        if (monitor === null || monitor === void 0 ? void 0 : monitor.monitorTypeId) {
            return monitor;
        }
        const validOptions = monitorTypeOptions.filter((x) => x.disabled === false);
        let tempMonitor = Object.assign({}, exports.defaultConfig);
        if (validOptions.length === 1) {
            tempMonitor = Object.assign({}, (_b = (_a = exports.monitorConfigs.find((x) => x.type === validOptions[0].value)) === null || _a === void 0 ? void 0 : _a.default) !== null && _b !== void 0 ? _b : exports.defaultConfig);
        }
        if (monitor === null || monitor === void 0 ? void 0 : monitor.applicationId) {
            tempMonitor.applicationId = monitor.applicationId;
        }
        return tempMonitor;
    }, [monitor, monitorTypeOptions]);
    const [currentMonitor, setCurrentMonitor] = (0, react_1.useState)(defaultMonitor);
    const prevDefault = (0, _hooks_1.usePrevious)(defaultMonitor, defaultMonitor);
    (0, react_1.useEffect)(() => {
        if (!(0, lodash_1.isEqual)(defaultMonitor, prevDefault)) {
            setCurrentMonitor(defaultMonitor);
        }
    }, [defaultMonitor, prevDefault]);
    const setSelectedMonitor = (0, react_1.useCallback)((newMonitor) => {
        setCurrentMonitor(newMonitor);
        if (onChange) {
            onChange(newMonitor);
        }
    }, [onChange]);
    (0, react_1.useEffect)(() => {
        if (!(0, lodash_1.isEqual)(defaultMonitor, prevDefault)) {
            setSelectedMonitor(defaultMonitor);
        }
    }, [defaultMonitor, prevDefault, setSelectedMonitor]);
    const handleOnTypeChange = (0, react_1.useCallback)((type) => {
        var _a, _b;
        if (type === undefined) {
            setSelectedMonitor(defaultMonitor);
            return;
        }
        const newMonitor = Object.assign({}, (_b = (_a = exports.monitorConfigs.find((config) => config.type === type)) === null || _a === void 0 ? void 0 : _a.default) !== null && _b !== void 0 ? _b : exports.defaultConfig);
        if (defaultMonitor === null || defaultMonitor === void 0 ? void 0 : defaultMonitor.applicationId) {
            newMonitor.applicationId = defaultMonitor.applicationId;
        }
        else if (currentMonitor === null || currentMonitor === void 0 ? void 0 : currentMonitor.applicationId) {
            newMonitor.applicationId = currentMonitor.applicationId;
        }
        setSelectedMonitor(newMonitor);
    }, [currentMonitor, defaultMonitor, setSelectedMonitor]);
    return (react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
        react_1.default.createElement(text_1.SonarText, { className: 'h2' }, isExistingMonitor ?
            sonar_ts_constants_1.SETTINGS_PULSE_COPY.MONITOR_TYPE :
            sonar_ts_constants_1.SETTINGS_PULSE_COPY.SELECT_MONITOR_TYPE),
        react_1.default.createElement(form_items_1.VisualPicker, { defaultValue: currentMonitor.monitorTypeId, message: sonar_ts_constants_1.COPY_SPECIFIC.NO_UNMONITORED_APPS_FOUND, name: fieldId === undefined ? 'monitorTypeId' : [fieldId, 'monitorTypeId'], onChange: handleOnTypeChange, options: monitorTypeOptions }),
        react_1.default.createElement(MonitorApplication_1.MonitorApplication, { editing: editing, fieldId: fieldId, hidden: Boolean((monitor === null || monitor === void 0 ? void 0 : monitor.id) || (monitor === null || monitor === void 0 ? void 0 : monitor.applicationId)), monitor: currentMonitor, onChange: (appId) => setSelectedMonitor(Object.assign(Object.assign({}, currentMonitor), { applicationId: appId })) }),
        react_1.default.createElement(react_if_1.When, { condition: currentMonitor.monitorTypeId && currentMonitor.applicationId },
            react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                react_1.default.createElement(MonitorDetails_1.MonitorDetails, { editing: editing, fieldId: fieldId, monitor: currentMonitor }),
                react_1.default.createElement(MonitorNotifications_1.MonitorNotifications, { editing: editing, fieldId: fieldId, schedule: currentMonitor === null || currentMonitor === void 0 ? void 0 : currentMonitor.notificationSchedule, stakeholderIds: currentMonitor === null || currentMonitor === void 0 ? void 0 : currentMonitor.stakeholderIds })))));
};
exports.MonitorMenu = MonitorMenu;
