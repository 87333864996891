"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceNamePrefixFilter = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const FilterTag_1 = require("@ui/table-modifier/filters/FilterTag");
const popups_1 = require("@lib/popups");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const filters_1 = require("@periscopes/general/filters");
const text_1 = require("@ui/text");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const prefixSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.setupAuditTrail.sourceNamePrefix.get);
const SourceNamePrefixFilter = ({ displayAsTag, id, format, label, onChange, value }) => {
    const noPrefix = '""';
    const { data } = (0, _hooks_1.useAsyncState)(prefixSelectors);
    const getSetupAuditTrailSourceNamePrefix = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSetupAuditTrailSourceNamePrefix);
    const valueWithEmpty = value === null || value === void 0 ? void 0 : value.map((prefix) => (Number(prefix) === 0 ? noPrefix : prefix));
    (0, _hooks_1.useOnMount)(() => {
        getSetupAuditTrailSourceNamePrefix();
    });
    const options = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = data === null || data === void 0 ? void 0 : data.sourceNamespacePrefixes.map((prefix) => ({
            label: prefix ? prefix : sonar_ts_constants_1.COPY_SPECIFIC.NO_PREFIX,
            value: prefix ? prefix : noPrefix
        }))) !== null && _a !== void 0 ? _a : [];
    }, [data]);
    if (displayAsTag) {
        return (react_1.default.createElement(FilterTag_1.FilterTag, { clearValue: [], format: format, id: id, label: (0, sonar_ts_lib_1.capitalize)(label), update: onChange, value: options === null || options === void 0 ? void 0 : options.filter((option) => valueWithEmpty === null || valueWithEmpty === void 0 ? void 0 : valueWithEmpty.includes(option.value)).map((option) => option.label).join(', ') }));
    }
    return (react_1.default.createElement("div", { className: 'filter-container' },
        react_1.default.createElement(text_1.SonarText, null, label),
        react_1.default.createElement(filters_1.OptionFilter, { className: 'filter', getPopupContainer: popups_1.getDirectParent, id: (0, lib_1.getFilterId)(id), maxTagCount: 'responsive', mode: 'multiple', onChange: (prefixes) => {
                onChange({
                    [id]: {
                        format,
                        value: prefixes
                    }
                });
            }, options: options, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(label), showArrow: true, value: valueWithEmpty })));
};
exports.SourceNamePrefixFilter = SourceNamePrefixFilter;
